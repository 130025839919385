export default {
  TABLE: {
    INTAKE: {
      COLUMN: [
        'intakeRef',
        'personName',
        'personIdentity',
        'assignedCounsellor',
        'presentingProblems',
        'intakeDate',
        'firstAppointmentDate',
        'intakeStatus',
        'actions',
      ],
      DATA: [
        'intakeRefAndAtRiskTags',
        'clientName',
        'identityDocument',
        'staffName',
        'presentingProblems',
        'intakeDate',
        'firstAppointmentDate',
        'intakeStatus',
        'showButton',
      ],
      FILTERS: ['centres', 'assignedCounsellor', 'singleSelectIntakeStatus', 'riskStatus'],
    },
    REPORTS: {
      CLIENT_SPECIAL_REPORT: {
        CLIENTS_TABLE_COLUMN: [
          'clientName',
          'caseNumber',
          'caseReference',
          'counsellor',
          'identityDocumentation',
          'riskStatus',
          'age',
          'maritalStatus',
          'gender',
          'individualIncome',
          'occupationStatus',
          'race',
          'religion',
          'educationLevel',
          'languages',
          'caseCategory',
          'primaryProblem',
          'lastModifed',
          'modifiedBy',
          'actions',
        ],
        STATISTICS: [
          'caseCategory',
          'riskStatus',
          'ageGroup',
          'maritalStatus',
          'gender',
          'individualIncome',
          'occupationStatus',
          'race',
          'religion',
          'educationLevel',
          'language',
        ],
      },
      INTAKE_SPECIAL_REPORT: {
        INTAKE_TABLE_COLUMN: [
          'id',
          'intake_ref',
          'client_name',
          'start_date',
          'referral_source_name',
          'last_referral_date',
          'actions',
          'not_making_appointment_reasons',
          'outcome',
          'assigned_counsellor',
          'assistant_counsellor',
          'intake_remarks',
          'last_appointment_date',
        ],
        INTAKE_TABLE_ROW: [
          'id',
          'intake_ref',
          'client_name',
          'start_date',
          'referral_source_name',
          'last_referral_date',
          'actions',
          'not_making_appointment_reasons',
          'outcome',
          'assigned_counsellor',
          'assistant_counsellor',
          'intake_remarks',
          'last_appointment_date',
        ],
      },
    },
    CASE: {
      COLUMN: [
        'caseNo',
        'caseRefId',
        'mainClient',
        'ljfsoIcNumber',
        'mainCounsellor',
        'dateOpened',
        'dateClosed',
        'caseType',
        'keyAssessedProblem',
        'actions',
      ],
    },
    CLIENT: {
      COLUMN: ['name', 'ic', 'contact', 'actions'],
    },
    CASE_SPECIAL_REPORT: {
      CASES_TABLE_COLUMN: [
        'id',
        'caseNumber',
        'caseRef',
        'caseStartDate',
        'primaryProblem',
        'secondaryProblems',
        'counsellingFeeAmount',
        'caseCategory',
        'numberOfSessions',
      ],
      STATISTICS: [
        'caseCategories',
        'presentingProblemsByCaseCategory',
        'counsellingFeesByCases',
        'counsellingFeesBySessions',
        'assessedProblems',
        'numberOfSessions',
      ],
    },
    INDIVIDUAL_COUNSELLOR_REPORT: {
      LIST_CASES_COLUMN: ['caseNumber', 'caseRef', 'caseType', 'primaryClient', 'icNumber', 'referralSources'],
    },
  },
};
