export default {
  VIEWS: {
    APP_LAYOUT: {
      DRAWER: {
        COMPONENTS: {
          main: [
            'createNewIntake',
            'cases',
            'events',
            'supervision',
            'calendar',
            'weeklySessions',
            'reportsExpand',
            'reports',
            'adminExpand',
            'admin',
            'auditLogs',
          ],
          reports: [
            'centreCaseSummaryReport',
            'individualCounsellorReport',
            'counsellorWorkloadReport',
            'supervisionSessionReport',
            'intakeSpecialReport',
            'caseSpecialReport',
            'clientSpecialReport',
            'caseMissingPrimaryProblemReport',
            'closedCaseReport',
            'dailyAppointmentReport',
            'numberOfSessionsReport',
            'dormantCaseReport',
          ],
          admin: [
            'clients',
            'staffs',
            'organisations',
            'centres',
            'supervision',
            'displaySettings',
            'emailSettings',
            'caseTypes',
            'caseCategories',
          ],
        },
      },
    },
    CASE: {
      DETAILS: {
        COMPONENTS: {
          caseRemarksSection: [],
        },
        ENTRIES: [
          'caseOverview',
          'caseIntSum',
          'caseReferralSources',
          'caseClientWorkersHistory',
          'caseRelatedCases',
          'caseLastModifiedStamp',
        ],
      },
      DETAILS_ASSESSMENT_INTERVENTION_SUMMARY: {
        COMPONENTS: {},
        ENTRIES: ['assessmentIntervention'],
      },
      DETAILS_CLIENTS_WORKER_HISTORY: {
        COMPONENTS: {
          clientsHistory: ['caseClients', 'caseWorkers', 'caseHistory'],
        },
        ENTRIES: ['clientsHistory'],
      },
      DETAILS_OTHER_CASEWORKER_REMARKS: {
        COMPONENTS: {},
        ENTRIES: [],
      },
      DETAILS_OVERVIEW: {
        COMPONENTS: {
          caseOverview: [
            'caseStatus',
            'caseNumber',
            'caseRefNumber',
            'caseType',
            'caseCategory',
            'intake',
            'intakeDate',
            'openedDate',
            'closedDate',
            'counsellingFee',
            'caseRemarks',
          ],
        },
        ENTRIES: ['caseOverview'],
      },
      DETAILS_RELATED_CASES: {
        COMPONENTS: {},
        ENTRIES: ['relatedCases'],
      },
      INTAKE_REFERRAL_SOURCES: {
        COMPONENTS: {},
        ENTRIES: ['sourceOfCase'],
      },
      SESSION_INFO: {
        submitButtons: [
          'edit',
          'markDefaulted',
          'markAttended',
          'undoDefaulted',
          'undoAttended',
          'completeSession',
          'undoCompleted',
          'nextDocumentation',
        ],
      },
      SUSPENSION_INFO: {
        SUSPENSION: {
          COMPONENTS: {},
          ENTRIES: [],
        },
        UNSUSPENSION: {
          COMPONENTS: {},
          ENTRIES: [],
        },
      },
    },
  },
};
