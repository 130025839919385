export default {
  TABLE: {
    CASE: {
      COLUMN: [
        'caseRefNo',
        'clientName',
        'ljsfoclIcNumber',
        'mainCaseWorker',
        'csDateOpened',
        'csDateClosed',
        'classification',
        'dateOfNextReview',
        'status',
      ],
      FILTERS: ['caseWorkers', 'caseStates', 'caseClassificationStatus'],
    },
  },
};
