import { MasqueradeData } from 'types/admin';
import { ChangePasswordPostData } from 'types/auth';

export default {
  AUTH: {
    BASE: 'auth',
    SIGN_IN: {
      ROUTE: 'sessions',
      BODY: (login: string, password: string, rememberMe: boolean) => {
        return {
          email: login,
          password: password,
          remember_me: rememberMe,
        };
      },
    },
    TWO_FACTOR: {
      BASE: 'two_factor',
      // TOTP
      GET_TOTP: {
        ROUTE: 'totp_requests',
      },
      SUBMIT_TOTP: {
        ROUTE: 'totp_requests',
        BODY: (otpAttempt: string) => {
          return {
            two_factor_otp_request: {
              otp: otpAttempt,
            },
          };
        },
      },
      GENERATE_TOTP: {
        ROUTE: 'totp_requests/generate',
      },
      // EMAIL OTP
      SEND_OTP: {
        ROUTE: 'email_requests/send',
        BODY: (action: string) => {
          return {
            two_factor_otp_request: {
              action,
            },
          };
        },
      },
      RESEND_OTP: {
        ROUTE: 'email_requests/resend',
        BODY: (action: string) => {
          return {
            two_factor_otp_request: {
              action,
            },
          };
        },
      },
      SUBMIT_OTP: {
        ROUTE: 'email_requests',
        BODY: (otpAttempt: string, action: string) => {
          return {
            two_factor_otp_request: {
              otp: otpAttempt,
              action,
            },
          };
        },
      },
    },
    SGPASS: {
      BASE: 'sgpass',
      INIT_SGPASS_AUTH: {
        ROUTE: 'create',
      },
      GET_SGPASS_AUTH_STATUS: {
        ROUTE: 'status',
        BODY: (code: string | null, state: string | null, redirectUri: string) => {
          return {
            code,
            state,
            redirect_uri: redirectUri,
          };
        },
      },
    },
    SIGN_OUT: {
      ROUTE: 'sessions',
    },
    FORGET_PASSWORD: {
      ROUTE: 'password',
      BODY: (email: string, redirectUrl: string) => {
        return {
          user: {
            email: email,
            redirect_url: redirectUrl,
          },
        };
      },
    },
    RESET_PASSWORD: {
      ROUTE: 'password',
      BODY: (token: string, password: string, passwordConfirmation: string) => {
        return {
          user: {
            reset_password_token: token,
            password: password,
            password_confirmation: passwordConfirmation,
          },
        };
      },
    },
    GET_CHANGE_PASSWORD_TOKEN: {
      ROUTE: 'edit',
    },
    MASQUERADE_AS: {
      ROUTE: 'masquerade',
      BODY: (data: MasqueradeData['masquerade_as_id']) => data,
    },
    UNMASQUERADE: {
      ROUTE: 'masquerade/back',
    },
  },
};
